import React from 'react';
import { PathService } from '../../services';
import { OurInsightsBlogsContext, TemplateProps } from '../../types';
import { OurInsightsBlogsView } from '../../views';

const OurInsightsBlogs: React.FunctionComponent<
  TemplateProps<OurInsightsBlogsContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OurInsightsBlogsView posts={JSON.parse(pageContext.posts)} />;
};

export default OurInsightsBlogs;
